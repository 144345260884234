import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { AvatarName } from '../UiKit/AvatarName';
import { EditName } from '../UiKit/EditName';
import { PopoverH } from '../UiKit/TooltipH';
import { Button, Popover } from 'antd';
import { Preloader } from '../UiKit/kit';


export function PentaName({
  penta = {},
  children,
  flat = false
}) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const [pentaInner, setPentaInner] = useState(penta)

  useEffect(() => {
    if (
      penta.token !== pentaInner.token
      || penta.members.length !== pentaInner.members.length
      || penta.members.name !== pentaInner.members.name
    ) {
      setPentaInner(penta)
    }
  }, [penta])


  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  return (
    <>


      {children && <>
        <div className="_f _f100 _fgap02 _fc">

          {/* {!penta.name && !flat && penta.members && penta.members.map((one, index, arr) => {
        return <span key={`name_${one.avatar.token}`}><span className="x_penta_name_1">{getName(one)}</span> {index < arr.length - 1 ? <span className="x_penta_name_and">& </span> : ''} {arr.length === 1 && <><span className="x_penta_name_and">& </span>...</>}</span>;
      })} */}
          {/* {!penta.name && flat && penta.members && penta.members.map((one, index, arr) => {
        return <React.Fragment key={`name_${one.avatar.token}`}><React.Fragment>{one.avatar.name ? one.avatar.name : context.getDefaultTitle(one.avatar.design)}</React.Fragment> {index < arr.length - 1 ? <React.Fragment>& </React.Fragment > : ''}</React.Fragment >;
      })} */}
          <b className="ellipsis">

            {!pentaInner.name && !flat && Boolean(pentaInner.members) && pentaInner.members.map((one, index, arr) => {
              return <span key={`name_${one.avatar.token}`}>
                <AvatarName avatar={one.avatar} /> {index < arr.length - 1 ? <span className="x_penta_name_and">& </span> : ''} {arr.length === 1 && <><span className="x_penta_name_and">& </span>...</>}
              </span>;
            })}

            {!pentaInner.name && flat && Boolean(pentaInner.members) && pentaInner.members.map((one, index, arr) => {
              return <React.Fragment key={`name_${one.avatar.token}`}>
                <React.Fragment><AvatarName avatar={one.avatar} /></React.Fragment> {index < arr.length - 1 ? <React.Fragment>& </React.Fragment > : ''}
              </React.Fragment >;
            })}


            {/* {pentaInner.name} */}

          </b>
          {children && <PopoverH
            fresh={true}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            title={<EditName
              name={pentaInner.name}
              placement='bottom'
              handleUpdate={(newName) => {
                hide();
                context.x.updatePenta({
                  'token': penta.token,
                  'name': newName
                }, (newPenta) => setPentaInner(newPenta))
              }}
            />}
          >
            <div className={`_f _fgap04 _fcc`}>
              {context.updatingPenta !== pentaInner.token && <><b>{pentaInner.name}</b> {children}</>}
              {context.updatingPenta === pentaInner.token && <><Preloader width={16} height={16} /></>}
            </div>
          </PopoverH>}
        </div>
      </>}

      {/* <Popover
        content={<a onClick={hide}>Close</a>}
        // title="Title"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button type="primary">Click me</Button>
      </Popover> */}

      {!children && <><PopoverH
        open={open}
        onOpenChange={handleOpenChange}
        trigger="click"
        title={<EditName
          name={pentaInner.name}
          placement='bottom'
          handleUpdate={(newName) => {
            hide();
            handleOpenChange(false);
            context.x.updatePenta({
              'token': penta.token,
              'name': newName
            }, (newPenta) => setPentaInner(newPenta))
          }}
        />}
      >
        <b className="ellipsis">
          <PentaNameText penta={pentaInner} />
        </b>
      </PopoverH>
      </>}


    </>
  )
}



export const PentaNameText = ({ penta, flat = true }) => {
  return (<>

    {/* {!penta.name && !flat && penta.members && penta.members.map((one, index, arr) => {
      return <span key={`name_${one.avatar.token}`}><span className="x_penta_name_1">{getName(one)}</span> {index < arr.length - 1 ? <span className="x_penta_name_and">& </span> : ''} {arr.length === 1 && <><span className="x_penta_name_and">& </span>...</>}</span>;
    })} */}
    {/* {!penta.name && flat && penta.members && penta.members.map((one, index, arr) => {
        return <React.Fragment key={`name_${one.avatar.token}`}><React.Fragment>{one.avatar.name ? one.avatar.name : context.getDefaultTitle(one.avatar.design)}</React.Fragment> {index < arr.length - 1 ? <React.Fragment>& </React.Fragment > : ''}</React.Fragment >;
      })} */}

    {!penta.name && !flat && penta.members && penta.members.map((one, index, arr) => {
      return <span key={`name_${one.avatar.token}`}><AvatarName avatar={one.avatar} /> {index < arr.length - 1 ? <span className="x_penta_name_and">& </span> : ''} {arr.length === 1 && <><span className="x_penta_name_and">& </span>...</>}</span>;
    })}

    {!penta.name && flat && penta.members && penta.members.map((one, index, arr) => {
      return <React.Fragment key={`name_${one.avatar.token}`}>
        <React.Fragment>
          <AvatarName avatar={one.avatar} />
        </React.Fragment>
        {index < arr.length - 1 ? <React.Fragment>& </React.Fragment > : ''}
      </React.Fragment >;
    })}

    {penta.name}

  </>)
}
