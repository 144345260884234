import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Sheet } from 'react-modal-sheet';

// Create a custom styled component for the Sheet
const CustomSheet = styled(Sheet)`
  .react-modal-sheet-container {
    background-color: white;
    border-radius: 1.6em;
    /* overflow: hidden; */
  }
  .react-modal-sheet-content {
    background-color: white;
  }
  .react-modal-sheet-header {
    border-top-left-radius: 1.6em;
    border-top-right-radius: 1.6em;
  }
`;


const Header = styled.div`
/* border: 1px solid red; */
/* width: 100%; */
left: 0;
/* right: 1em; */
padding: 0 1.4em;
height: 4em;
display: flex;
flex-direction: row;
align-items: center;
background: white;
z-index: 100;
cursor: pointer;
user-select: none;
position: relative;
top: ${(props) => `${props.offsetY}px`};
`;


export function PopInfo(props) {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 991); // Set initial state based on window size

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991); // Update state based on window size
    };

    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup listener on component unmount
    };
  }, []);

  return (
    <>
      {isMobile ? (<>
        {/* TODO: Comment this if fails */}
        <PopModal {...props} />
        {/* <PopWeb {...props} /> */}
      </>
      ) : (
        <PopWeb {...props} />
      )}
    </>
  );
}


export function PopWeb(props) {

  const {
    id,
    children,
    handleClose = () => void (0),
    type = '',
    title,
    icon,
    addContent,
    addClassName
  } = { ...props };


  const [isMobile, setIsMobile] = useState(window.innerWidth < 479);
  const [offsetY, setOffsetY] = useState(0); // Initial transformY is 200px
  const [addClassNameInner, setAddClassNameInner] = useState(addClassName); // Initial transformY is 200px

  useEffect(() => {
    setAddClassNameInner(addClassName)
  }, [addClassName]);

  return (<>
    <div className={`x_info0 ${addClassName}`}
      onClick={(e) => !isMobile && e.stopPropagation()}
    >
      <div className="x_info_z inact active" onClick={handleClose} />
      <div className={`x_info ${addClassNameInner}`}
        onClick={(e) => e.stopPropagation()}
      // ref={mainElementRef}
        style={{ transform: `translateY(${offsetY}px)` }}
      >
        <div className="x_info_mobileindicator" />
        <div
          className="x_info_inner active"
          style={{
            // maxHeight: offsetY,
            // overflow: 'hidden',
            transition: 'max-height 0.2s ease',
          }}
        >
          <div
            className={`m_b_h m_b_h--${type}`}
          >
            {/* <div className="m_b_h_icon">
              <div className="t_icon t_icon--20">
                {icon}
              </div>
            </div> */}
            {/* <div className="m_b_h_title">
              {title}
            </div> */}
            {/* <div className="x_edu_title x_edu_title--center">
              {title}
            </div> */}
            <div className={`x_pop_right x_pop_right--${type}`}>
              <div className="x_action x_action--white" onClick={handleClose}>
                <div className="t_icon t_icon--20">
                  close
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>

  </>
  );
}



export function PopModal({
  active, // External parameter to control modal visibility
  id,
  children,
  handleClose = () => void (0),
  type = '',
  title,
  icon,
  addContent,
  addClassName = ''
}) {
  const [isOpen, setOpen] = useState(active); // Set initial state based on active prop
  const [currentSnap, setCurrentSnap] = useState(1); // Track the current snap point
  const sheet = useRef(null); // Initialize the ref

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll', 'zoomed-out');
    } else {
      document.body.classList.remove('noscroll', 'zoomed-out');
    }
    return () => {
      document.body.classList.remove('noscroll', 'zoomed-out');
    };
  }, [isOpen]);

  useEffect(() => {
    // Update modal visibility when active prop changes
    setOpen(active);
  }, [active]);

  useEffect(() => {
    if (isOpen && sheet.current) {
      sheet.current.snapTo(3);
    }
  }, [isOpen]);

  const handleScroll = (event) => {
    const { scrollTop } = event.target;

    // If the user scrolls to the top, snap to the bottom (100)
    if (scrollTop === 0) {
      sheet.current?.snapTo(3); // Snap to the 100% point (index 3)
    }
  };

  return (
    <CustomSheet
      style={{
        zIndex: 1000
      }}
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
        handleClose();
      }}
      // snapPoints={[100, 0.5, 0]} // Adjust snap points as needed
      snapPoints={[-50, 0.5, 200]}
      initialSnap={1}
      ref={sheet} // Attach the ref to the Sheet
      onSnap={(snap) => setCurrentSnap(snap)} // Update current snap point
    >
      <Sheet.Container>
        <Sheet.Header>
          <div className="x_info_mobileindicator" />


          <div className='x_mobile_info_title'
            style={{
              height: '4em'
            }}
          >
            {title}


            {/* <div className="x_edu_title x_edu_title--center">
              {title}
            </div> */}
            <div
              className={`m_b_h m_b_h--${type}`}
            >

              <div className={`x_pop_right x_pop_right--${type}`}>
                <div className="x_action x_action--white mobile_v_yes"
                >
                  {(currentSnap === 1 || currentSnap === 2) && <div className="t_icon t_icon--20"
                    onClick={() => {
                      sheet.current?.snapTo(0);
                    }}
                  >
                    keyboard_arrow_up
                  </div>}
                  {currentSnap === 0 && <div className="t_icon t_icon--20"
                    onClick={() => {
                      sheet.current?.snapTo(1);
                    }}
                  >
                    keyboard_arrow_down
                  </div>}
                </div>
                <div className="x_action x_action--white" onClick={() => {
                  handleClose()
                  setOpen(false);
                }}>
                  <div className="t_icon t_icon--20">
                    close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sheet.Header>
        <Sheet.Content style={{ paddingBottom: sheet.current?.y }}>
          {/* <Sheet.Scroller draggableAt="both" onScroll={handleScroll}> */}
          {children}
          {/* </Sheet.Scroller> */}
        </Sheet.Content>
      </Sheet.Container>


      <Sheet.Backdrop onClick={() => {
        setOpen(false);
        handleClose();
      }} />
    </CustomSheet >
  );
}