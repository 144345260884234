import { Popover, Tooltip } from 'antd';
import React, { useState } from 'react';
import { tooltipTheme } from './tooltip_theme';

export const TooltipH = (props) => {
  const {
    title,
    placement = 'top',
  } = { ...props }
  return <Tooltip
    title={title}
    placement={placement}
    {...tooltipTheme}
    {...props}
  />
}


export const PopoverH = (props) => {
  const {
    title,
    placement = 'top',
  } = { ...props }

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  // return < Tooltip
  //   title={title}
  //   {...tooltipTheme}
  //   {...props}
  // />
  return <Popover
    title={title}
    // open={open}
    // onOpenChange={handleOpenChange}
    placement={placement}
    {...tooltipTheme}
    {...props}
  />
}