import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { IconList } from '../UiKit/IconList';
import { TooltipH } from '../UiKit/TooltipH';
import { PentaContainer } from './PentaContainer';
import { PentasChoose } from './PentasChoose';


export function Pentas({
  icon = 'diversity_3',
  max = 2,
  min = 0,
  title = '',
  create
}) {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!context.state.peoplePanel) {
      setExpanded(false)
    }
  }, [context.state.peoplePanel])


  const handleChoose = (val) => {
    !context.state.peoplePanel && context.setState.showPeoplePanel(['pentas'])
    setExpanded(val)
  }

  const [active, setActive] = useState()
  const { pentaId } = useParams();


  const count = context.pentas.filter(one => (one.members.length > min - 1 && one.members.length < max + 1)).length

  return (
    <>




      <PentasChoose
        pentaId={expanded}
        setExpanded={setExpanded}
        expanded={expanded}
      />

      <div
        name={`pentas`}
        className={context.state.peoplePanel ? "x_choose_section x_choose_section--v" : "x_choose_section"}
      >
        <div className={context.state.peoplePanel ? "x_choose_section_title0 x_choose_section_title0--v" : "x_choose_section_title0"}>
          <div className={context.state.peoplePanel ? "x_choose_section_header x_choose_section_header--v" : "x_choose_section_header"}>
            <div className={context.state.peoplePanel ? "x_choose_section_title x_choose_section_title--v" : "x_choose_section_title"}>

              <IconList icon={icon} q={count} /> {title}

              {Boolean(context.pentas.length) && <div className="x_choose_menu_counter">
                {count}
              </div>}
              <div className="x_choose_section_header_grad0 r" />
            </div>

            {context.state.peoplePanel && <div className="x_choose_section_header_right">
              <TooltipH title={$ui('people_private_tooltip')} placement='bottom'>
                <div className="x_choose_menu_label"><div className="t_icon">lock</div>
                  {/* <div className="mobile_v_no">
                    {$ui('people_private_list')}
                  </div> */}
                </div>
              </TooltipH>
            </div>}


          </div>

        </div>





        <div className={context.state.peoplePanel ? "x_choose x_choose_pentas x_choose_pentas--v" : "x_choose x_choose_pentas"}>


          {context.pentas
            .filter(one => one.members.length > min - 1 && one.members.length < max + 1)
            .map((one, ind) =>
            <PentaContainer
              key={`penta_${one.token}`}
              setExpanded={setExpanded}
              peoplePanel={context.state.peoplePanel}
              handleChoose={handleChoose}
              quantity={context.pentas.length}
              listId={one.token}
              penta={one}
            />
          )}


          {create && context.state.peoplePanel && <div className={context.state.peoplePanel ? "x_penta0 x_penta0--v" : "x_penta0"}>
            <div
              onClick={() => context.x.createPentaWithMe()}
              className="x_penta_table x_penta_table--add"><div className="x_choose_one_action"><div className="t_icon t_icon--100"><div>add</div></div><div>
                {$ui('penta_new')}
              </div></div></div>
          </div>
          }







        </div>
      </div>




    </>
  )
}


