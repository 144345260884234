import React, { useContext } from 'react';
import { StateContext } from '../../contexts/stateContext';
import { Planets } from '../UiKit/Planets';
import { colors } from '../UiKit/Colors';


export const PentaBodyPlanets = ({
  penta,
  label = 'des',
  preview = 'comp',
  setPreview = () => void (0),
  colorTheme = 'base'
}) => {

  const context = { ...useContext(StateContext) };



  return (<>
    <div className="x_bodygraph_planets_penta">


    {preview === 'comp' && <>


        {label === 'des' && <>
        <div className="x_bodygraph_planets x_bodygraph_planets--penta">
          <Planets
              colorTheme={'composite1'}
              // handleHover={(gate) => {
              //   setPreview(0);
              //   context.setState.setSearch(gate)
              // }}
              interactive={false}
              noPlanet={true}
            pentaView={true}
              label='des'
              design={penta.members[0].avatar.design}
            />
          </div>
          {penta.members[0] && <div className="x_bodygraph_planets x_bodygraph_planets--penta x_bodygraph_planets_des">
            <Planets
              colorTheme={'composite2'}
              interactive={false}
              pentaView={true}
              onlyPlanet={true}
              label='des'
              design={penta.members[0].avatar.design}
            />
          </div>}
          {penta.members[1] && <div className="x_bodygraph_planets x_bodygraph_planets--penta">
          <Planets
              colorTheme={'composite2'}
              interactive={false}
              // handleHover={(gate) => {
              //   setPreview(0);
              //   context.setState.setSearch(gate)
              // }}
              noPlanet={true}
            pentaView={true}
              label='des'
            design={penta.members[1].avatar.design}
          />
          </div>}
        </>}

        {penta.members[0] && label === 'prs' && <>
          <div className="x_bodygraph_planets x_bodygraph_planets--penta x_bodygraph_planets_prs">
          <Planets
              colorTheme={'composite1'}
              interactive={false}
            pentaView={true}
            noPlanet={true}
              label='prs'
            design={penta.members[0].avatar.design}
          />
        </div>

          <div className="x_bodygraph_planets x_bodygraph_planets--penta x_bodygraph_planets_prs">
            <Planets
              colorTheme={'composite1'}
              interactive={false}
              pentaView={true}
              onlyPlanet={true}
              label='prs'
              design={penta.members[0].avatar.design}
            />
          </div>

          {penta.members[1] && <div className="x_bodygraph_planets x_bodygraph_planets--penta">
          <Planets
              colorTheme={'composite2'}
              interactive={false}
            pentaView={true}
              noPlanet={true}
              label='prs'
            design={penta.members[1].avatar.design}
          />
          </div>}
        </>}
    </>}

    {preview !== 'comp' && <>
        {label === 'des' && penta.members[preview] && <>
        <div className="x_bodygraph_planets x_bodygraph_planets--penta">
          <Planets
              colorTheme={'base'}
              interactive={false}
            pentaView={true}
              label='des'
              design={penta.members[preview].avatar.design}
            />
          </div>
        </>}


        {label === 'prs' && penta.members[preview] && <>
          <div className="x_bodygraph_planets x_bodygraph_planets--penta x_bodygraph_planets_prs">
          <Planets
              colorTheme={'base'}
              interactive={false}
            pentaView={true}
              label='prs'
            design={penta.members[preview].avatar.design}
          />
          </div>
        </>}

      </>}
    </div>
  </>)
}

