import React, { useContext, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { PentaBody } from '../Penta/PentaBody';
import { PentaStats } from '../Penta/PentaStats';
import { Background } from '../UiKit/Background';
import { PentaPanel } from '../UiKit/PentaPanel';
import { Preview } from '../UiKit/Preview';
import { getPentaDesign } from '../utilsPenta';
import { colors } from '../UiKit/Colors';

export const PentaFirst = React.memo((props) => {
  const { penta, id, user, design, avatar, children, loading } = { ...props };

  // const [preview, setPreview] = useState(0);
  const [preview, setPreview] = useState('comp');


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;


  const bodyContainer = useRef(null);
  const parentContainer1 = useRef(null);
  const parentContainer2 = useRef(null);



  const [markers, setMarkers] = useState('em');





  // const design1 = penta.members[0]
  //   && penta.members[0].avatar
  //   && penta.members[0].avatar.design.type
  //   ? penta.members[0].avatar.design : parseNewDesign(default_design);

  // const design2 = penta.members[1]
  //   && penta.members[1].avatar
  //   && penta.members[1].avatar.design.type
  //   ? penta.members[1].avatar.design : parseNewDesign(default_design);

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;

  const profileId1 = design1.profile && design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile && design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';


  const pentaDesign = getPentaDesign(penta);

  const cyclePreview = () => {
    setPreview((prev) => {
      if (prev === 'comp') return 1;
      if (prev === 1) return 0;
      if (prev === 0) return 1;
    });
  }

  return (
    <>
      <div className="x_edu_content0" >
        <div className="x_edu_content">

          <div className="_fv _fgap2 _f100">


            {penta.members.length > 0 && <div className="x_edu_penta0">



              <div className="x_edu_ava1">
                {penta.members[0] && <PentaPanel member={penta.members[0]} />}
                <div className="x_edu_ava1_body">
                  <div className={`x_click x_click--penta ${preview === 0 ? 'active' : ''}`} style={{ zIndex: 4 }}
                    onClick={() => setPreview((prev) => prev === 0 ? 'comp' : 0)}
                    onMouseEnter={() => setPreview(0)}
                    onMouseOut={() => setPreview('comp')}
                  />
                  <div className="x_edu_ava1_bodygraph" ref={parentContainer1}>
                    {/* LEFT  */}
                    <Preview
                      default_colors='composite1'
                      // penta={penta}
                      parentContainer={parentContainer1}
                      design={design1}
                      aura={false}
                    />
                  </div>
                  <Background design={design1}>
                  </Background>
                </div>

                <div className="x_penta_typeprofile">
                  {$type(design1['type'], 'title_compact')} {$profile(profileId1, 'profile')}<br />"{$profile(profileId1, 'title')}"
                </div>

                <div className="x_edu_penta_colors0">
                  <div className="x_edu_penta_color"
                    style={{ backgroundColor: colors[context.state.colorMode]['composite1']['prs'] }}
                  >{$ui('body_prs')}</div>
                  <div className="x_edu_penta_color"
                    style={{ backgroundColor: colors[context.state.colorMode]['composite1']['des'] }}
                  >{$ui('body_des')}</div>
                </div>



              </div>


              <div className="x_edu_penta_preview" >

                <div className="x_edu_penta_switcher0"
                  onClick={() => cyclePreview()}
                  onMouseOut={() => setPreview('comp')}
                >
                  <div className="x_edu_penta_switcher">
                    <div className={`x_edu_penta_switcher_c ${preview === 0 ? 'active' : ''}`}>
                      <div className={`x_edu_penta_switcher_rl ${preview === 0 ? 'active' : ''}`}>
                        <div className="t_icon">chevron_left</div>
                      </div>
                    </div>
                    <div className={`x_edu_penta_switcher_c ${preview === 'comp' ? 'active' : ''}`}>
                      <div className="t_icon">{preview === 'comp' ? <div>radio_button_checked</div> : <div>radio_button_unchecked</div>}</div>
                    </div>
                    <div className={`x_edu_penta_switcher_c ${preview === 1 ? 'active' : ''}`}>
                      <div className={`x_edu_penta_switcher_rl ${preview === 1 ? 'active' : ''}`}>
                        <div className="t_icon">chevron_right</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="x_body_content" ref={bodyContainer}>
                  <PentaBody
                    hoverable={true}
                    markers={markers}
                    composite={true}
                    planets={true}
                    preview={preview}
                    setPreview={setPreview}
                    numbers={true}
                    penta={penta}
                    parentContainer={bodyContainer}
                    aura={false}
                    // className='x_bodygraph_body--main'
                  />
                </div>
              </div>



              {penta.members[1] && <div className="x_edu_ava1">
                <PentaPanel member={penta.members[1]} />
                <div className="x_edu_ava1_body">


                  <div className={`x_click x_click--penta ${preview === 1 ? 'active' : ''}`} style={{ zIndex: 4 }}
                    onClick={() => setPreview((prev) => prev === 1 ? 'comp' : 1)}
                    onMouseEnter={() => setPreview(1)}
                    onMouseOut={() => setPreview('comp')}
                  />
                  <div className="x_edu_ava1_bodygraph" ref={parentContainer2}>
                    {/* RIGHT */}
                    <Preview
                      default_colors='composite2'
                      // aura={false}
                      // width={180}
                      // height={200}
                      parentContainer={parentContainer2}
                      design={design2}
                      aura={false}
                    />
                  </div>
                  <Background design={design2}>
                  </Background>
                </div>
                <div className="x_penta_typeprofile">
                  {$type(design2['type'], 'title_compact')} {$profile(profileId2, 'profile')}<br />"{$profile(profileId2, 'title')}"
                </div>

                <div className="x_edu_penta_colors0">
                  <div className="x_edu_penta_color"
                    style={{ backgroundColor: colors[context.state.colorMode]['composite2']['prs'] }}
                  >{$ui('body_prs')}</div>
                  <div className="x_edu_penta_color"
                    style={{ backgroundColor: colors[context.state.colorMode]['composite2']['des'] }}
                  >{$ui('body_des')}</div>
                </div>

              </div>}




              {!penta.members[1] && <div className="x_edu_ava1">
                <PentaPanel />
                <div className="x_edu_ava1_body">
                  <div className="x_penta_slot x_penta_slot--main x_penta_slot--empty"
                    onClick={() => context.setState.showPeoplePanel(['pentas'])}
                  >
                    <div className="t_icon t_icon--40">
                      <div>add</div>
                    </div>
                  </div>
                </div>

                <div className="x_penta_typeprofile">

                  {/* {$type(design2['type'], 'title')} {$profile(profileId2, 'profile')}  "{$profile(profileId2, 'title')}" */}
                </div>





              </div>}


            </div>}


            <div className="_f _fv _fm">
              <div className="x_edu_content_h x_edu_content_h--3" style={{ textAlign: 'center' }}>
                {/* {$ui('penta_composite')}: "{$type(pentaDesign['type'], 'title')}" */}
              </div>
              <div className="x_edu_content_h x_edu_content_h--3">
                <PentaStats penta={penta} size={30} markers={markers} handleMarkers={setMarkers} />
              </div>
            </div>

          </div>

        </div>

        {/* {penta && <Background
          design={getPentaDesign(penta)}
        />} */}

      </div>





    </>
  )
})




