import React, { useContext, useEffect, useState } from 'react';
import { AdminMenu } from './AdminMenu';

import calc19830526T092800 from './calc19830526T092800.json';
import calc19880227T140100 from './calc19880227T140100.json';
import calc19890901T100000 from './calc19890901T100000.json';
import calc19890619T193100 from './calc19890619T193100.json';
import calc19820924T042500 from './calc19820924T042500.json';

import TextArea from 'antd/es/input/TextArea';
import { Button, Input, Segmented, Select } from 'antd';
import { Preview } from '../UiKit/Preview';
import { drawProfile, drawType, parseNewDesign, convertToHumanReadableDate } from '../utilsDesign';


const API_URL = process.env.REACT_APP_API_URL;
const SERVICE_DESIGN = process.env.REACT_APP_SERVICE_DESIGN;



const TESTDATA = {
  '1982-09-24T04:25:00': calc19820924T042500,
  '1983-05-26T09:28:00': calc19830526T092800,
  '1988-02-27T14:01:00': calc19880227T140100,
  '1989-06-19T19:31:00': calc19890619T193100,
  '1989-09-01T10:00:00': calc19890901T100000,
}



export function AdminAPI(props) {


  const [currentDate, setCurrentDate] = useState('1983-05-26T09:28:00');

  const [fetchedData, setFetchedData] = useState(null);

  const [currentView, setCurrentView] = useState('raw');

  const [numberOfDesigns, setNumberOfDesigns] = useState(10);

  useEffect(() => {
    fetchData(currentDate).then(data => setFetchedData(data));
  }, [currentDate]);

  const fetchData = async (date) => {
    const response = await fetch(`${SERVICE_DESIGN}/api/date?date=${date}&zone=UTC`);
    const data = await response.json();
    return data;
  }

  const [randomDesigns, setRandomDesigns] = useState([]);

  const randomDates = [];

  // Function to generate a random date between two dates
  function getRandomDate(start, end) {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('.')[0]; // Format to 'YYYY-MM-DDTHH:mm:ss'
  }

  // Generate random dates
  const startDate = new Date(1900, 0, 1); // January 1, 1900
  const endDate = new Date(); // Current date
  for (let i = 0; i < numberOfDesigns; i++) {
    randomDates.push(getRandomDate(startDate, endDate));
  }

  const generateRandomDesigns = async () => {
    const startDate = new Date(1900, 0, 1); // January 1, 1900
    const endDate = new Date(); // Current date
    const randomDates = [];

    // Generate N random dates
    for (let i = 0; i < numberOfDesigns; i++) {
      randomDates.push(getRandomDate(startDate, endDate));
    }

    // Fetch data for all random dates
    const randomDesigns = await Promise.all(randomDates.map(date => fetchData(date).then(data => {
      // Assuming you want to process the data here before setting it
      return data; // Modify this if you need to transform the data
    })));
    setRandomDesigns(randomDesigns);
  }


  return (<>
    <AdminMenu active="api" />
    <div className="b" style={{ position: 'relative', minHeight: '100vh', padding: '2em 2em 12em 2em', background: '#efefef' }}>
      <div className='_fv _fgap2'>
        <h1>🌀 API</h1>

        <div className="_fv">

        <div className="_f _fm">
          <h3>UTC:</h3>
          <Select
            className='_f100'
            value={currentDate}
            onChange={setCurrentDate}
              style={{
                width: 200
              }}
          >
            {Object.keys(TESTDATA).map((key) => (
              <Select.Option value={key}>{key}</Select.Option>
            ))}
          </Select>

        </div>

        <div className="_f _fgap2 _rel _ftop">




            <div className="_fv _rad1 _rel"
              style={{

              }}>
              <h2>Их</h2>

              <TextArea value={JSON.stringify(TESTDATA[currentDate], null, 2)} readOnly rows={30} cols={50} />
            </div>




          <div className="_fv _rad1 _rel"
            style={{

            }}>
            <div className="_fm _fgap2">
              <h2>Наш</h2>
              <Segmented
                size='small'
                options={[
                  { label: 'Raw', value: 'raw' },
                  { label: 'Parsed', value: 'parsed' },
                ]}
                value={currentView}
                onChange={setCurrentView}
              />
            </div>

            {currentView === 'raw' && <TextArea value={JSON.stringify(fetchedData, null, 2)} readOnly rows={30} cols={50} />}
            {currentView === 'parsed' && <TextArea value={JSON.stringify(parseNewDesign(fetchedData), null, 2)} readOnly rows={30} cols={50} />}

            </div>
            <div className="_fv  _fgap2 _fcc">
              {fetchedData && <>

                <div className="_rel">
                  <Preview
                    width={200}
                    height={120}
                    design={parseNewDesign(fetchedData)} />
                </div>
                <h3>{drawType(parseNewDesign(fetchedData).type)} {drawProfile(parseNewDesign(fetchedData).profile)}</h3>
              </>
              }
            </div>




          </div>
        </div>

        <div className="_fv">

          <h1>Random design generator</h1>

          <div className="_f">
            <Input
              placeholder="Number of designs"
              value={numberOfDesigns}
              onChange={(e) => setNumberOfDesigns(e.target.value)}
              style={{
                width: 100
              }}
            />
            <Button
              onClick={generateRandomDesigns}
            >
              Generate random designs
            </Button>
          </div>

          <div className="x_choose x_choose--v x_choose--followings  ">
            {randomDesigns.map((design, index) => (
              <div key={index} className="_fv _fgap0 _rel">
                {design && <>
                  <div className="_rel">
                    <Preview
                      width={100}
                      height={100}
                      design={parseNewDesign(design)}
                    />
                  </div>
                  <h2>{drawType(parseNewDesign(design).type, 'short')} {drawProfile(parseNewDesign(design).profile)}</h2>
                  {/* <p>{convertToHumanReadableDate(design.pd)}</p> */}
                  <p>{design.pd}</p>
                </>}
              </div>
            ))}
          </div>

        </div>



      </div>
    </div>
  </>
  );
};
