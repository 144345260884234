import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LangContext } from '../../contexts/langContext';
import { gates_dictionary } from '../../static/gates_dictionary';
import { planets_dictionary } from '../../static/planets_dictionary';
import { theme } from '../UiKit/Colors';
import { Gate } from '../UiKit/Gate';
import { RadioItem } from '../UiKit/RadioItem';
import { Svg } from '../UiKit/Svg';
import { Switch } from '../UiKit/Switch';
import { prepareGates } from '../utilsDesign';


export const Gates = React.memo((props) => {

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  const {
    advanced,
    design = {},
    videos = 'inline'
  } = { ...props }


  const [openOnly, setOpenOnly] = useState(true)
  const [filter, setFilterInner] = useState()

  const setFilter = (value) => {
    if (filterByType(gatesActivations, "circuit", value).length) {
      setFilterInner(value)
    }
  }


  const gatesActivations = prepareGates(design['gates']);

  // const channels = gatherUserChannels(design.gates)
  const channelsGates = design.channels.map(one => one.gates).flat()


  const myGates = []
  Object.keys(gatesActivations)
    .filter(one => {
      return openOnly ? !channelsGates.includes(parseInt(one)) : true
    })
    .forEach(gateId => {
      myGates.push({
        ...gates_dictionary[gateId],
        activations: [...new Set(gatesActivations[gateId].map(one => one.label))],
        activatedWIth: gatesActivations[gateId]
      })
    })



  const filterByType = (array = [], what = '', value = []) => {
    if (!value) return array;
    return array.filter(oneChannel => {
      return oneChannel[what].filter(oneValue => {
        return value.includes(oneValue)
      }).length === oneChannel[what].length && oneChannel[what].length === value.length;
    })
  }


  const [page, setPage] = useState('circuit')

  const sorts = {
    'circuit': [['individual'], ['tribal'], ['collective']],
    'type': [['projected'], ['generated'], ['manifested']],
    'activations': [['des'], ['des', 'prs'], ['prs']],
  }

  const [showActivations, setShowActivations] = useState(false);

  return (
    <>

      <div className="x_edu_content">

      <div className="_fv _fgap2 _f100">

        <div className="x_edu_select0">
          <div className="x_choose_list">
            {/* <div className="x_group_label">
              {$ui('group_by')}:
            </div> */}



            <RadioItem
              active={page === 'circuit'}
              onClick={() => setPage('circuit')}
            >
              <div className='mobile_v_yes'>{$ui('group_by_circuits')}</div>
              <div className='mobile_v_no'>{sorts.circuit.map((one, ind, arr) => <span key={`c_${one}`}>{$ui(one.toString())}{ind < arr.length - 1 && <span className='x_choose_menu_delim'> /</span>} </span>)}</div>
            </RadioItem>



            {/* <RadioItem
              active={page === 'type'}
              onClick={() => setPage('type')}
            >
              <div className='mobile_v_yes'>{$ui('group_by_types')}</div>
              <div className='mobile_v_no'>{sorts.type.map((one, ind, arr) => <span key={`t_${one}`}>{$ui(one)}{ind < arr.length - 1 && <span className='x_choose_menu_delim'> /</span>} </span>)}</div>

            </RadioItem> */}

            <RadioItem
              active={page === 'activations'}
              onClick={() => setPage('activations')}
            >
              <div className='mobile_v_yes'>{$ui('group_by_activations')}</div>
              <div className='mobile_v_no'>{sorts.activations.map((one, ind, arr) => <span key={`t_${one}`}>{$ui(one)}{ind < arr.length - 1 && <span className='x_choose_menu_delim'> /</span>} </span>)}</div>

            </RadioItem>

          </div>
          {/* <div className="x_edu_select_right">
            <Switch
              size='sm'
              label={$ui('edu_show_activations')}
              active={showActivations}
              handle={() => setShowActivations(prev => !prev)}
            />
          </div> */}
            <div className="x_edu_select_right mobile_v_no">
            <Switch
              size='sm'
              label={$ui('edu_show_openonly')}
              active={openOnly}
              handle={() => setOpenOnly(prev => !prev)}
            />
          </div>
        </div>


        <div className="x_edu_activations_table">


          {sorts[page].map(oneCircuit => <div
            key={oneCircuit}
            className="x_edu_activations_column"
          >

            <div className='x_edu_h2'>{$ui(oneCircuit)}</div>


            <div className="x_edu_channels">
              {/* <div className="x_edu_content_label">Gates</div> */}
              {filterByType(myGates, page, oneCircuit)
                .map(oneGate => {
                  const oneGateId = parseInt(oneGate.gate);
                  return (<Gate
                    advanced={advanced}
                    key={`gate_${oneGateId}_${oneCircuit}`}
                    label={false}
                    design={design}
                    gateNumber={oneGateId}
                    {...oneGate}
                    showActivations={showActivations}
                    // expert={expert}
                    videos={videos}
                  />)
                })}


              {/* {!Boolean(filterByType(gatesArray, 'circuit', oneCircuit).length) && <>{$ui('no_gates')}</>} */}


            </div>
          </div>)}

        </div>
      </div>


      </div>

    </>
  )
})





export const GatePlanet = React.memo(({
  gate,
  design,
  line = true,
  color = false,
  tone = false,
  inLine,
  inColor,
  inTone
}) => {

  const context = { ...useContext(LangContext) }
  const $ui = context.$ui;
  const $planet = context.$planet;

  return (<>

    <div className={`x_edu_gate_planet ${gate['label'] === 'prs' ? 'prs' : 'des'}`}
      style={{ marginBottom: '-0.4em', position: 'relative' }}
    >


      <Planet
        planet={gate['planet']}
        label={gate['label']}
      />

      <div className="">
        {`${$planet(gate['planet'], 'title')} ${inLine ? $ui('in_line') : ''} ${gate['line']} ${inColor ? $ui('in_color') : ''} ${color ? gate['color'] : ''}  ${inTone ? $ui('in_tone') : ''} ${tone ? gate['tone'] : ''} `}
      </div>

    </div>
    {/* <div className="x_planet_gates">
      <GateInfo design={design} gate={gate} design={design} />
    </div> */}

  </>
  )
})



export const Planet = React.memo(({ planet = 'Sun', label }) => {

  const context = { ...useContext(LangContext) }
  const $ui = context.$ui;
  const $planet = context.$planet;

  return (
    <div className="x_bodygraph_pl">
      <Svg width={20} height={20}
        color={label === 'des' ? theme.designColor : theme.personalityColor}
      >{planets_dictionary[planet]['svg']}</Svg>
    </div>
    // <div style={{ textTransform: 'uppercase', fontSize: '12px' }}>

    //   <div className={planet === 'South_Node' || planet === 'North_Node' ? "x_bodygraph_pl x_bodygraph_pl--node" : "x_bodygraph_pl"}
    //     style={{ textTransform: 'uppercase' }}
    //   >
    //     {$planet(planet, 'glyph')}
    //   </div>
    // </div>
  )
})



const CSStitleLine = styled.div`
width: 100%;
  display: flex;
  justify-content: stretch;
`
export const CSSplanetLine = styled.div`

  display: flex;
  grid-column-gap: 0.4em;
  margin-left: -0.2em;
  margin-top: 0.1em;
  flex-wrap: wrap;
`

export const CSSplanet = styled.div`
  color: ${({ label }) => label === 'prs' ? 'black' : 'red'};
  display: flex;
  grid-column-gap: 0em;
  align-items: center;
  // font-size: 14px;
  font-size: 1em;
`


