import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { StateContext } from '../../contexts/stateContext';
import { planets_dictionary } from '../../static/planets_dictionary';
import { nodes_dn, nodes_up } from '../../static/variables_dictionary';
import {
  getDesignNodeColor,
  getDesignSunColor,
  getPersNodeColor,
  getPersSunColor,
  handleGateClick,
  findGateByPlanet
} from '../utilsDesign';
import { colors, theme } from './Colors';
import { Svg } from './Svg';
import { TooltipH } from './TooltipH';
import X from './X';


export const Planets = React.memo(({
  colorTheme = 'base',
  interactive = true,
  handleHover,
  label = 'prs',
  design,
  advanced,
  fontSize,
  highlightedGate = [],
  setHighlightedGate = () => void (0),
  pentaView,
  noPlanet,
  onlyPlanet
}) => {
  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  const location = useLocation();
  const navigate = useNavigate();

  const makePlanets = (design) => {

    if (design.design && design.personality && !Boolean(design.gates.length)) return {}

    let planets = {};

    // if (label === 'prs' && design.personality.length) {
    //   planets['prs'] = {}
    // }
    // if (label === 'des' && design.personality.length) {
    //   planets['des'] = {}
    // }
    planets = {
      'prs': {},
      'des': {}
    };



    Object.keys(planets_dictionary).forEach(one => {
      if (design.design && design.design.length) planets['des'][one] = {};
      if (design.personality && design.personality.length) planets['prs'][one] = {};
    });


    design['gates'].forEach(one => {
      planets[one['label']][one['planet']] = {
        gate: one['gate'],
        line: one['line'],
        color: one['color'],
        tone: one['tone'],
        base: one['base'],
      };
    });
    return planets;
  };

  const planets = makePlanets(design);
  // const planets2 = {
  //   des: {
  //     ...design['design'].map(one => {
  //       let p = {}
  //       p[one['planet']] = { ...one }
  //       return p
  //     })
  //   },
  //   prs: {
  //     ...design['personality'].map(one => {
  //       let p = {}
  //       p[one['planet']] = { ...one }
  //       return p
  //     })
  //   },
  // };


  const planetClass = (planet) => planet.toLowerCase();
  const advancedPlanet = (planet) => {
    if (planet === 'Sun' ||
      planet === 'Earth' ||
      planet === 'South_Node' ||
      planet === 'North_Node') return true;
  };


  // handleHover = handleHover ? handleHover : () => context.setState.setSearch;
  handleHover = setHighlightedGate;


  const handleGateClickInner = (gateNumber) => {
    context.setState.setSearch([gateNumber]);
    handleGateClick(gateNumber, location, navigate);
  };

  const isStudy = location.pathname.includes('study');
  const { what } = useParams();

  const infoType = what ? what.split(':')[0] : null;
  let infoValue = what ? what.split(':')[1] : null;


  return (
    <>
      {design && design.id !== "dummy" && <div
        className={label === 'prs'
        ? `x_bodygraph_planets ${advanced ? 'x_bodygraph_planets--magic' : ''} x_bodygraph_planets_prs ${advanced ? 'x_bodygraph_planets--adv' : ''} ${pentaView ? 'x_bodygraph_planets--penta' : ''}`
        : `x_bodygraph_planets  ${advanced ? 'x_bodygraph_planets--magic' : ''} ${advanced ? 'x_bodygraph_planets--adv' : ''} ${pentaView ? 'x_bodygraph_planets--penta' : ''}`}
      >


        {Object.keys(planets_dictionary).map(planetName => {
          return (<div

            onClick={(e) => {
              e.stopPropagation();
              handleGateClickInner(planets[label][planetName]['gate'], location, navigate);
            }}

            onMouseOver={() => {
              if (planets[label][planetName]['gate']) {
                // console.log('planets[label][planetName]', planets[label][planetName]['gate']);
                handleHover([planets[label][planetName]['gate']]);
              }
            }}
            onMouseOut={() => handleHover(
              isStudy
                && infoType === 'gate'
                && infoValue === planets[label][planetName]['gate'].toString()
                ?
                [parseInt(infoValue)] :
                [])
            }

            style={{
              position: 'relative',
              color: label === 'des' ? colors[context.state.colorMode][colorTheme].des : colors[context.state.colorMode][colorTheme].prs,
              borderRadius: '0.4em',
              // boxShadow: (planets[label][planetName]
              //   && planets[label][planetName]['gate']
              //   && highlightedGate.includes(planets[label][planetName]['gate']))
              //   ||
              //   (isStudy
              //     && infoType === 'gate'
              //     && infoValue === planets[label][planetName]['gate'].toString())

              //   ? `0 0 0px 2px ${colors[context.state.colorMode][colorTheme].highlightedGateColor}`
              //   : 'none'
            }}
            key={`planet_${planetName}_${label}`}
            className={`x_bodygraph_planet ${Boolean(planets[label][planetName]) && interactive ? 'x_bodygraph_planet--hoverable' : 'inact'} x_bodygraph_planet--${planetClass(planetName)} ${isStudy
              && infoType === 'gate'
              && infoValue === planets[label][planetName]['gate'].toString() ? 'active' : ''}`}
            alt={planets_dictionary[planetName]['title']}
          >


            {advanced && Boolean(planets[label][planetName]) && planetName === 'Earth' && <div className={`x_bodygraph_node_arrow ${label === 'prs' ? 'x_bodygraph_node_arrow--prs' : ''}`}>
              {label === 'des' && <Svg width={16} height={16} color={colors[context.state.colorMode][colorTheme].des}>
                {getDesignSunColor(design, true) === 'up' ? nodes_up : nodes_dn}
              </Svg>}

              {label === 'prs' && <Svg width={16} height={16} color={colors[context.state.colorMode][colorTheme].prs}>
                {getPersSunColor(design, true) === 'up' ? nodes_up : nodes_dn}
              </Svg>}

            </div>}



            {advanced && Boolean(planets[label][planetName]) && planetName === 'South_Node' && <div className={`x_bodygraph_node_arrow ${label === 'prs' ? 'x_bodygraph_node_arrow--prs' : ''}`}>

              {label === 'des' && <>
                <TooltipH title={`Sun tone: ${getDesignSunColor(design)}`} placement='bottom'>
                  <Svg width={16} height={16} color={colors[context.state.colorMode][colorTheme].des}>
                    {getDesignNodeColor(design, true) === 'up' ? nodes_up : nodes_dn}
                  </Svg>
                </TooltipH>
              </>}

              {label === 'prs' && <Svg width={16} height={16} color={colors[context.state.colorMode][colorTheme].prs}>
                {getPersNodeColor(design, true) === 'up' ? nodes_up : nodes_dn}
              </Svg>}

            </div>}


            {/* {planets[label][planetName] && <div className={planetName === 'South_Node' || planetName === 'North_Node' ? "x_bodygraph_pl x_bodygraph_pl--node" : "x_bodygraph_pl"}>
                          {planets_dictionary[planetName]['glyph']}
                        </div>} */}

            <div className="_f100 _fgap0 _fspace">
              {!onlyPlanet && planets[label][planetName] && <div className="x_bodygraph_pl_gate" style={{ alignItems: 'flex-end' }}>
                <div className="x_bodygraph_pl_value">
                  <X value={planets[label][planetName]['gate']}>
                    {planets[label][planetName]['gate']}
                  </X>
                </div>
                .
                {planets[label][planetName]['line']}
                {advanced && advancedPlanet(planetName) &&
                  <div
                    className="x_bodygraph_pl_gate"
                    style={{ fontSize: '0.7em', fontWeight: 'bolder', opacity: 0.8 }}>
                    .
                    <div className="x_bodygraph_pl_value">
                      <X value={planets[label][planetName]['color']}>
                        {planets[label][planetName]['color']}
                      </X>
                    </div>
                    .
                    <div className="x_bodygraph_pl_value">
                      <X value={planets[label][planetName]['tone']}>
                        {planets[label][planetName]['tone']}
                      </X>
                    </div>
                    .
                    <div className="x_bodygraph_pl_value">
                      <X value={planets[label][planetName]['base']}>
                        {planets[label][planetName]['base']}
                      </X>
                    </div>
                  </div>}
              </div>}

              {!noPlanet && planets[label][planetName] && <PlanetIco
                planet={planetName}
                label={label}
                design={design}
              />}
            </div>

          </div>);
        })}


      </div>}
    </>
  );
});


export const PlanetIco = React.memo(({
  planet = 'Sun',
  label = 'prs',
  design,
  size = 20,
  colorTheme = 'base'
}) => {
  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  return (
    <div className="x_bodygraph_pl">
      <Svg width={size} height={size}
        color={label === 'des' ? colors[context.state.colorMode][colorTheme].des : colors[context.state.colorMode][colorTheme].prs}
      >{planets_dictionary[planet]['svg']}</Svg>
    </div>
  )
})


export const PlanetActivation = React.memo(({
  planet = 'Sun',
  label = 'prs',
  design,
  size = 20,
  advanced = true,
  colorTheme = 'base'
}) => {
  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  const gate = findGateByPlanet(design.gates, planet, label);

  return <div className="_f100 _fgap0 _fspace">

    <PlanetIco
      planet={planet}
      label={label}
      design={design}
      colorTheme={colorTheme}
    />

    <div className="_f" style={{ alignItems: 'flex-end' }}>
      {gate['gate']}.{gate['line']} <span style={{ fontSize: '0.7em', fontWeight: 'bolder', opacity: 0.8 }}>&nbsp;.{gate['color']}.{gate['tone']}.{gate['base']}</span>
    </div>

  </div>

})