import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { FollowList } from '../FollowList';

export function UserNetwork(props) {
  const {
    useravatarId,
    user,
    children,
    startState = 'followings'
  } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(startState);
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const drawSelector = () => (<div className="m_b_toggles">
    <div className="ui_radiogroup_h">

      <div className={state === 'followers' ? `ui_radio_h active` : `ui_radio_h`}
        onClick={() => setState('followers')}
      >
        <b>
          {$ui('pop_followers')}
        </b>
        <div className="x_choose_menu_counter">{user.aggr.followers_count}</div>
      </div>

      <div className={state === 'followings' ? `ui_radio_h active` : `ui_radio_h`}
        onClick={() => setState('followings')}
      >
        <b>
          {$ui('pop_following')}
        </b>
        <div className="x_choose_menu_counter">{user.aggr.followings_count}</div>
      </div>
    </div></div>)


  return (
    <>








        {state === 'followers' && <FollowList
          count={user.aggr.followers_count}
          token={useravatarId}
          list={'followers'}
      >
        {drawSelector()}
      </FollowList>}


        {state === 'followings' && <FollowList
          count={user.aggr.followings_count}
          token={useravatarId}
          list={'followings'}
      >
        {drawSelector()}
      </FollowList>}



    </>
  )
}