import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { PentaName } from '../Penta/PentaName';
import { PentaPHS } from '../Penta/PentaPHS';
import { Persona } from '../Persona';
import { PublicSocial } from '../PublicSocial';
import { Social } from '../Social';
import { Start } from '../Start';
import { Avatar } from '../UiKit/Avatar';
import { Background } from '../UiKit/Background';
import { Button } from '../UiKit/Button';
import { Icon } from '../UiKit/Icon';
import { PentaShare } from '../UiKit/PentaShare';
import { Pop } from '../UiKit/Pop';
import { TooltipH } from '../UiKit/TooltipH';
import { Topbar } from '../UiKit/Topbar';
import { WithVersion } from '../UiKit/VersionToggler';
import { getPentaDesign } from '../utilsPenta';
import { VideoPop } from '../VideoPop';
import { Full } from './Full';
import { PentaChannels } from './PentaChannels';
import { PentaFirst } from './PentaFirst';
import { Study } from './Study';
import { Cross } from './Cross';

export function PentaView(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const {
    penta,
    parentContainer
  } = { ...props };

  const bodyContainer = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({});
  const [scrolled, setScrolled] = useState(false);
  const [compare, setCompare] = useState(penta.members.length > 2);

  const [sharePop, setSharePop] = useState(false);

  const processPenta = (penta) => {
    return {
      ...JSON.parse(JSON.stringify(penta)),
      "members": [...JSON.parse(JSON.stringify(penta)).members.map(userAvatar => ({
      ...userAvatar,
      "avatar": {
        ...userAvatar.avatar,
        "design": userAvatar.avatar.design.profile ? userAvatar.avatar.design : default_design
      }
    }))
    ]
    }
  }

  const [pentaInner, setPentaInner] = useState(
    processPenta(penta)
  )
  useEffect(() => {
    setPentaInner(processPenta(penta))
  }, [penta])





  useEffect(() => {
    // Check the URL hash on component mount
    const hash = window.location.hash;

    // Set comp based on the hash only if members length is 2 or less
    if (pentaInner.members.length > 2) {
      if (hash === '#compare') {
        setCompare(true);
      } else if (hash === '#composite' || hash === '') {
        setCompare(false);
      }
    } else {
      setCompare(false); // Always set comp to false if members length > 2
    }
  }, []); // Dependency to re-run if members length changes

  useEffect(() => {
    if (pentaInner.members.length <= 2) {
      if (compare) {
        window.location.hash = 'compare';
      } else {
        window.location.hash = 'composite';
      }
    } else {
      window.location.hash = 'compare';
    }
  }, [compare, pentaInner.members.length]);

  useEffect(() => {
    if (pentaInner.members.length > 2) {
      if (!compare) setCompare(true);
    }
  }, [compare, pentaInner.members.length]);



  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 40) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollable = useRef(null);


  const handleCloseInfoPop = () => {
    document.body.classList.remove('noscroll');
    context.setState.setHighlighted()
    context.setState.setSearch([])

    const removeStudySegments = (pathname) => {
      const segments = pathname.split('/study')
      return segments.length > 1 ? segments[0] : null
    };
    const currentPath = location.pathname;
    const newPath = removeStudySegments(currentPath);
    if (newPath) navigate(newPath);
  };
  const isStudy = location.pathname.includes('study');


  return (
    <>

      {sharePop
        &&
        <Pop
          title={$ui('but_public_link_short')}
          // icon='person_add'
          handleClose={() => setSharePop()}
        >

          <PentaShare penta={penta} handleClose={() => setSharePop()} />

        </Pop>}

      <VideoPop />

      {/* {context.me && <MobMenu penta={penta} />} */}

      <div className={!context.state.peoplePanel ? "x_layout" : "x_layout x_layout--people"}

        onClick={() => handleCloseInfoPop()}
      >




        <Topbar
          // user={user}
          // avatar={avatar}
          penta={pentaInner}
          mode2={scrolled}
        />


        <Study
          penta={pentaInner}
          // user={user ? user : null}
          // avatar={avatar}
          handleClose={() => navigate('../')}
        />


        {context.me && <Social hidePreviews={penta.members.length > 2} />}
        {!context.me && <PublicSocial hidePreviews={penta.members.length > 3} />}



        {Boolean(penta) &&
          <div className={!context.state.peoplePanel ? "x_edu_block" : "x_edu_block x_edu_block--open"}>
            <div className="x_edu_c">
              <div className="l_panel" >
                {/* <div className={`x_edu0 ${isStudy ? 'x_edu0--info' : ''}`} ref={scrollable}> */}

                <div className={`x_edu0 ${penta.members.length < 3 ? 'x_edu0--composite' : 'x_edu0--penta'} ${isStudy ? 'x_edu0--info' : ''}`} ref={scrollable}>

                  <div className={!context.state.peoplePanel ? "x_edu_block" : "x_edu_block x_edu_block--open"}>


                    <div className="x_0_user">
                      <div className="x_0_user_block">

                        {/* <div className="x_0_penta_avas">
                      <div className="x_0_pentatop_1">
                        <Avatar
                          onClick={() => 1}
                          avatar={penta.members[0].avatar}
                          type='calc'
                        />
                      </div>
                      <Avatar
                        onClick={() => 1}
                        penta={penta}
                        type='calc'
                      />
                      <div className="x_0_pentatop_1">
                        <Avatar
                          onClick={() => 1}
                          avatar={penta.members[1] ? penta.members[1].avatar : { design: {} }}
                          type='calc'
                        />
                      </div>
                    </div> */}

                        <div className="x_0_penta_avas">
                          <div className="x_calc_result_name0">



                            <div className="x_calc_result_name ">
                              <Avatar
                                onClick={() => 1}
                                penta={pentaInner}
                                type='calc'
                              />

                              {(
                                context.me
                                && Boolean(context.pentas.filter(onePenta => onePenta.token === penta.token).length))
                                && <div className="x_calc_result_title ellipsis">
                                  <PentaName
                                    penta={penta}
                                    flat={true}
                                  />
                                </div>}

                            </div>
                          </div>


                          {!Boolean(penta.token) && <Button
                            onClick={() => context.x.createPenta(
                              penta.members.map(one => one.avatar.token),
                              (newPenta) => navigate(`/p/${newPenta.token}`)
                            )}
                          >
                            {$ui('penta_save_composite')}
                          </Button>}






                          {pentaInner.members.length < 3 && <>
                            <div className="_fgap0 mobile_v_no">

                              <TooltipH title={$ui('penta_switch_composite')} placement='bottom'>
                                <div className={`x_body_view ${!compare ? 'active' : ''}`} onClick={() => setCompare(false)}>
                                  <div className="t_icon t_icon--20" style={{ opacity: !compare ? 1 : 0.5 }}>
                                    group
                                  </div>
                                </div>
                              </TooltipH>

                              <TooltipH title={$ui('penta_switch_side')} placement='bottom'>
                                <div className={`x_body_view ${compare ? 'active' : ''}`} onClick={() => setCompare(true)}>
                                  <div className="t_icon t_icon--20" style={{ opacity: compare ? 1 : 0.5 }}>
                                    compare
                                  </div>
                                </div>
                              </TooltipH>

                            </div>
                          </>}


                          {/* {Boolean(pentaInner.token)
                            && context.me
                            && <div className="x_button x_button--flat">
                            <TooltipH title={$ui('penta_private')} placement='bottom'>
                              <div className="">
                                <Icon>lock</Icon>
                              </div>
                            </TooltipH>
                          </div>} */}


                          {Boolean(penta.token) && <div className="x_0_user_followbutton">
                            <Button
                              ghost
                              onClick={() => setSharePop(true)}
                            >
                              <Icon>qr_code</Icon>
                            </Button>
                          </div>}







                        </div>

                      </div>
                    </div>

                    <>




                      {!compare && <>
                        <PentaFirst penta={pentaInner} aura={true} />
                        {/* //TODO: PENTS PHS */}

                        <div className='x_edu_content_section'>
                          <div className="_f _fgap1 _f100">

                            <PentaPHS
                              penta={pentaInner}
                              advanced={true}
                            />
                          </div>
                        </div>

                        <div className="_fv _fgap2">
                          <PentaChannels penta={pentaInner} />
                        </div>



                      </>}


                      {compare && penta.members.length < 6 && <>
                        <>


                          <div className="_fv _fgap2 mobile_v_no">
                            <div className="_ftop _fspace _fgap1">
                              {penta.members.map(one => {
                                return <div
                                  key={`penta_body_${penta.token}_${one.avatar.token}`}
                                  className='_fv  _t08'
                                  style={{ width: `${(100 / penta.members.length - 2)}%` }}
                                >
                                  <div className='_fv _fnoshrink _fcc' style={{
                                    // height: 240,
                                    position: 'sticky', top: 0, zIndex: 100,
                                    // height: '12em',
                                    paddingTop: '2em'
                                  }}>
                                    <Persona
                                      noClick={!context.me}
                                      width={80}
                                      user={one.user}
                                      avatar={one.avatar}
                                      hideName={!context.me}
                                    // avatar={{
                                    //   ...one.avatar,
                                    //   name: context.me ? one.avatar.name : ''
                                    // }}
                                    />
                                  </div>

                                  <Full
                                    numbers={true}
                                    aura={true}
                                    user={one.user}
                                    hoverable={true}
                                    avatar={one.avatar}
                                    noSettings={true}
                                    noSearch={true}
                                    noHints={true}
                                    penta={penta}
                                    addControls={
                                      <DeleteFromPenta
                                        penta={penta}
                                        avatar={one.avatar}
                                        disabled={penta.members.length < 3}
                                      />}
                                  />


                                </div>
                              })}


                            </div>

                            <div className='x_edu_content_section'>
                              <div className="_f _fgap1 _f100">

                                <PentaPHS
                                  videos={false}
                                  penta={pentaInner}
                                  advanced={true}
                                />
                              </div>
                            </div>




                            {/* <div className="_ftop _fspace _fgap0">
                          {penta.members.map(one => {
                            return <div key={`penta_phs_${one.avatar.token}`} className='_t08'
                              style={{ width: `${(100 / penta.members.length - 2)}%` }}
                            >
                              <PentaPHS penta={{
                                members: [one]
                              }} />
                            </div>
                          })}
                        </div> */}


                            {/* <PentaChannels
                          penta={penta}
                            view={[
                              // 'em',
                              // 'same',
                              // 'dom',
                              'individual'
                            ]}
                        /> */}

                          </div>

                        </>
                      </>}

                      {/* TODO: penta mobile */}
                      <div className="x_edu_content _f  _fgap2 _fgrow _f100">

                        <div className="">
                          <div className='x_edu_content_h x_edu_content_h--2'>
                            {$ui('penta_cross_title')}
                          </div>
                        </div>

                        <div className="x_edu_content_inner">
                          <div className='x_edu_content_section'>
                            <div className="_f _fspace">
                              {penta.members.map(one => {
                                return <div key={`penta_body_${one.avatar.token}`}
                                  className='_t08vh'
                                  style={{ width: `${(100 / penta.members.length - 2)}%` }}
                                >
                                  <Cross
                                    design={one.avatar.design}
                                  />
                                </div>
                              })}
                            </div>
                          </div>
                        </div>
                      </div>



                      {false && penta.members.length < 6 && <>
                        <>
                          <Start
                            penta={pentaInner}

                          // addControls={
                          //   <DeleteFromPenta
                          //     penta={penta}
                          //     avatar={one.avatar}
                          //     disabled={penta.members.length < 3}
                          //   />}

                          // narrative={context.narratives['simple']}
                          />


                          {/* <div className="_ftop _fspace _fgap0">
                          {penta.members.map(one => {
                            return <div key={`penta_body_${one.avatar.token}`}
                              className='_t08'
                              style={{ width: `${(100 / penta.members.length - 2)}%` }}
                            >
                              <DesignPages
                                user={one.user}
                                avatar={one.avatar}
                                narrative={context.narratives['simple']}
                              />
                              <Full
                                numbers={true}
                                aura={true}
                                user={one.user}
                                avatar={one.avatar}
                                noSettings={true}
                                noSearch={true}
                                addControls={
                                  <DeleteFromPenta
                                    penta={penta}
                                    avatar={one.avatar}
                                    disabled={penta.members.length < 3}
                                  />}
                              />
                            </div>
                          })}
                          </div> */}


                          {/* <div className="_ftop _fspace _fgap0">
                          {penta.members.map(one => {
                            return <div key={`penta_phs_${one.avatar.token}`} className='_t08'
                              style={{ width: `${(100 / penta.members.length - 2)}%` }}
                            >
                              <PentaPHS penta={{
                                members: [one]
                              }} />
                            </div>
                          })}
                        </div> */}


                          {/* <PentaChannels
                          penta={penta}
                            view={[
                              // 'em',
                              // 'same',
                              // 'dom',
                              'individual'
                            ]}
                        /> */}

                        </>
                      </>}

                      {/* {comp && penta.members.length < 6 && <>

                        <div className="_ftop _fspace _fgap0">


                          {penta.members.map(one => {
                            return <div key={`penta_body_${one.avatar.token}`}
                              className='_t08'
                              style={{ width: `${(100 / penta.members.length - 2)}%` }}>
                              <DesignPages
                                key={`penta_body_${one.avatar.token}`}
                                avatar={one.avatar}
                                narrative={context.narratives['simple']}
                              />
                            </div>
                          })}

                        </div>
                      </>
                      } */}

                    </>
                  </div>

                </div>


              </div>
            </div>
          </div>
        }


        <Background
          aura={false}
          style={{ zIndex: -1, position: 'fixed' }}
          design={getPentaDesign(pentaInner)}
        />


      </div>






    </>
  )
}







const DeleteFromPenta = ({ penta = {}, avatar = {}, disabled }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const deleteFromPenta = () => context.x.deleteFromPenta(penta.token, avatar.token)

  const del = <Button disabled={disabled} red onClick={disabled ? void (0) : deleteFromPenta}>Remove</Button>


  return <Button sm ghost icon='more_vert' tooltip={del} tooltipPlacement={'bottom'}>

  </Button>

}




