
import { bwColor } from '../components/UiKit/Colors';


const ln = 'en';


export const centersTagline = {
  en: {
    HD: 'Inspiration and Ideas',
    AA: 'Logic and Conceptualization',
    TT: 'Communication and Manifestation',
    GC: 'Identity, Love, and Direction',
    HT: 'sense of Self and Willpower',
    SN: 'Instinct and Intuition',
    SP: 'Emotions and Feelings',
    SL: 'Life Force and Vitality',
    RT: 'Pressure and Adaptability',
  }
}

export const centersDesc = {
  en: {
    HD: 'Inspiration and Ideas',
    AA: 'Logic and Conceptualization',
    TT: 'Communication and Manifestation',
    GC: 'Identity, Love, and Direction',
    HT: 'sense of Self and Willpower',
    SN: 'Instinct and Intuition',
    SP: 'Emotions and Feelings',
    SL: 'Life Force and Vitality',
    RT: 'Pressure and Adaptability',
  }
}

export const centerGates = {
  HD: [64, 61, 63],
  AA: [47, 24, 4, 17, 43, 11],
  TT: [62, 23, 56, 35, 12, 45, 33, 8, 31, 20, 16],
  GC: [1, 13, 25, 46, 2, 15, 10, 7],
  HT: [21, 40, 26, 51],
  SN: [48, 57, 44, 50, 32, 28, 18],
  SP: [6, 37, 22, 36, 30, 55, 49],
  SL: [5, 14, 29, 59, 9, 3, 42, 27, 34],
  RT: [53, 60, 52, 19, 39, 41, 58, 38, 54]
}

export const centersPercentage = {
  HD: 41,
  AA: 41,
  TT: 50,
  GC: 47,
  HT: 27,
  SN: 57,
  SP: 47,
  SL: 70,
  RT: 60,
}

export const centersTitles = {
  en: {
    AA: 'Anja',
    HD: 'Head',
    TT: 'Throat',
    GC: 'G',
    HT: 'ego',
    SN: 'Spleen',
    SP: 'Solar Plexus',
    SL: 'Sacral',
    RT: 'Root',
  }
}

export const authorities = {
  en: {
    AA: 'Mental',
    HD: 'Mental',
    TT: '',
    GC: 'Self-projected',
    HT: 'ego-projected',
    SN: 'Splenic',
    SP: 'Emotional',
    SL: 'Sacral',
    RT: '',
    HT_GC: 'ego-projected',
    outher_auth: 'Mental',
    RFL: 'Lunar'
  }
}

export const authoritiesArray = [
  { 'key': 'mental', 'en': 'Mental', 'centers': ['AA', 'HD'] },
  { 'key': 'self', 'en': 'Self-projected', 'centers': ['GC'] },
  { 'key': 'ego', 'en': 'ego-projected', 'centers': ['HT', 'HT_GC'] },
  { 'key': 'splenic', 'en': 'Splenic', 'centers': ['SN'] },
  { 'key': 'emotional', 'en': 'Emotional', 'centers': ['SP'] },
  { 'key': 'sacral', 'en': 'Sacral', 'centers': ['SL'] },
  { 'key': 'outer', 'en': 'Outer', 'centers': ['sounding_board'] },
  // { 'key': 'lunar', 'en': 'Lunar', 'centers': ['RFL'] }
]


export const authoritiesDesc = {
  en: {
    SP: 'Decision-making based on the individual\'s emotional waves and achieving emotional clarity before choosing.',
    SL: 'Decision-making guided by the body\'s gut response or "Sacral" response.',
    SN: 'Intuitive decision-making relying on gut instincts and a deep sense of knowing.',
    HT: 'Decision-making driven by a strong inner voice of personal willpower and conviction.',
    HD: 'Decision-making through a structured and logical thought process, waiting for mental clarity before deciding.',
    AA: 'Decision-making through a structured and logical thought process, waiting for mental clarity before deciding.',
    RFL: 'Decision-making influenced by the phases of the moon, finding clarity and alignment during specific lunar phases.'
  }
}


export const centersDefinition = {
  en: {
    open: {
      HD: 'individuals with an open Head center can have an open and receptive mind, making them more susceptible to mental influences and conditioning from others. They may experience an ongoing stream of thoughts and ideas from different sources.',
      AA: 'Those with an open Anja center can be more susceptible to external mental influences and conditioning. They may have a tendency to adopt the thought patterns and beliefs of others.',
      HT: 'People with an open ego center may have an inconsistent sense of self-worth and can be more susceptible to the opinions and expectations of others. They may seek validation and recognition from external sources.',
      SP: 'People with an open Solar Plexus center are sensitive to the emotional energy of others and can easily take on the emotional waves and moods of those around them.',
      SL: 'individuals with an open Sacral center do not have a consistent and sustainable life force energy. They may rely on the energy and vitality of others to sustain their activities and can be prone to feeling pressure to keep up with the energy of those with a defined Sacral center.',
      SN: ' Those with an open Spleen center can be highly attuned to the instincts and fears of others. They may experience a fluctuating sense of intuition and can be influenced by the survival instincts of those with a defined Spleen center.',
    },
    defined: {
      AA: 'Those with a defined Anja center have a consistent mental awareness and perception. They may have a focused and penetrating intellect and can bring clarity and insight to their experiences.',
      HD: 'People with a defined Head center have a consistent and reliable mental process. They can have clear and structured thinking patterns and mental clarity that allows them to make decisions and manifest ideas.',
      HT: 'individuals with a defined ego center have a strong sense of self-worth and willpower. They can confidently express their individuality and have a consistent inner drive and motivation.',
      SN: 'Those with a defined Spleen center have a reliable source of intuition and instincts. They can trust their gut feelings and have a consistent awareness of potential dangers or opportunities.',
      SP: ' individuals with a defined Solar Plexus center have a consistent emotional wave and emotional clarity within themselves. They may experience a stable emotional state and can radiate emotional energy to those around them.',
      SL: 'People with a defined Sacral center have a sustainable life force energy, known as the Sacral response. They have a consistent source of vitality and can generate their own energy to sustain their activities.',
    }
  }
}





export const centersI = {
  AA: { title: centersTitles[ln]['AA'], definition: centersDefinition[ln], auth: authorities[ln]['AA'], authDescr: authoritiesDesc[ln]['AA'], percent: centersPercentage['AA'], titleShort: 'AA', descriptionShort: centersTagline[ln]['AA'], color: { bw: bwColor, color: 'green' }, motor: false, pressure: false, awareness: true, },
  HD: { title: centersTitles[ln]['HD'], definition: centersDefinition[ln], auth: authorities[ln]['HD'], authDescr: authoritiesDesc[ln]['HD'], percent: centersPercentage['HD'], titleShort: 'HD', descriptionShort: centersTagline[ln]['HD'], color: { bw: bwColor, color: 'yellow' }, motor: false, pressure: true, awareness: false, },
  TT: { title: centersTitles[ln]['TT'], definition: centersDefinition[ln], auth: authorities[ln]['TT'], authDescr: authoritiesDesc[ln]['TT'], percent: centersPercentage['TT'], titleShort: 'TT', descriptionShort: centersTagline[ln]['TT'], color: { bw: bwColor, color: 'red' }, motor: false, pressure: false, awareness: false, },
  GC: { title: centersTitles[ln]['GC'], definition: centersDefinition[ln], auth: authorities[ln]['GC'], authDescr: authoritiesDesc[ln]['GC'], percent: centersPercentage['GC'], titleShort: 'GC', descriptionShort: centersTagline[ln]['GC'], color: { bw: bwColor, color: 'yellow' }, motor: false, pressure: false, awareness: false, },
  HT: { title: centersTitles[ln]['HT'], definition: centersDefinition[ln], auth: authorities[ln]['HT'], authDescr: authoritiesDesc[ln]['HT'], percent: centersPercentage['HT'], titleShort: 'HT', descriptionShort: centersTagline[ln]['HT'], color: { bw: bwColor, color: 'red' }, motor: true, pressure: false, awareness: false, },
  SN: { title: centersTitles[ln]['SN'], definition: centersDefinition[ln], auth: authorities[ln]['SN'], authDescr: authoritiesDesc[ln]['SN'], percent: centersPercentage['SN'], titleShort: 'SN', descriptionShort: centersTagline[ln]['SN'], color: { bw: bwColor, color: 'red' }, motor: false, pressure: false, awareness: true, },
  SP: { title: centersTitles[ln]['SP'], definition: centersDefinition[ln], auth: authorities[ln]['SP'], authDescr: authoritiesDesc[ln]['SP'], percent: centersPercentage['SP'], titleShort: 'SP', descriptionShort: centersTagline[ln]['SP'], color: { bw: bwColor, color: 'red' }, motor: true, pressure: false, awareness: false, },
  SL: { title: centersTitles[ln]['SL'], definition: centersDefinition[ln], auth: authorities[ln]['SL'], authDescr: authoritiesDesc[ln]['SL'], percent: centersPercentage['SL'], titleShort: 'SL', descriptionShort: centersTagline[ln]['SL'], color: { bw: bwColor, color: 'red' }, motor: true, pressure: false, awareness: false, },
  RT: { title: centersTitles[ln]['RT'], definition: centersDefinition[ln], auth: authorities[ln]['RT'], authDescr: authoritiesDesc[ln]['RT'], percent: centersPercentage['RT'], titleShort: 'RT', descriptionShort: centersTagline[ln]['RT'], color: { bw: bwColor, color: 'brown' }, motor: true, pressure: true, awareness: false, },
}





export const getCenter = (center, typ = 'title') => {
  return centersI[center][typ];
}