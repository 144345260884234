import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { Avatar } from '../UiKit/Avatar';
import { AvatarName } from '../UiKit/AvatarName';
import { Channel } from '../UiKit/Channel';
import { Txt } from '../UiKit/Txt';
import {
  getPentaDesign,
  penta_em,
  penta_same,
  penta_dom,
  getDomChannels
} from '../utilsPenta';
import { Svg } from '../UiKit/Svg';

export const PentaChannels = React.memo((props) => {

  const {
    penta,
    view = ['em', 'same', 'dom', 'individual']
  } = { ...props };

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;

  const profileId1 = design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';

  const pentaDesign = {
    ...getPentaDesign(penta),
    // 'gates': prepareChannels(getPentaDesign(penta).gates, true)
  };




  return (
    <>





      {view.includes('em') &&
        <div className='x_edu_content'>
          <div className='x_edu_content_inner'>
            <div className="_fv _fgap06">
              <div className='x_edu_content_h x_edu_content_h--2'>

                <Svg width={28} height={28}>{penta_em}</Svg> {$ui('penta_em_channels')} <div className="x_choose_menu_counter" style={{ fontSize: '0.7em' }}>
                  {getPentaDesign(penta)['em'].length}
            </div>

          </div>
          <div className="x_edu_content_desc">
            <Txt>{$ui('penta_em_channels', 'rich')}</Txt>
          </div>

        </div>

            <div className="x_edu_channels_inline" >

              {!getPentaDesign(penta)['em'].length && <div className="x_edu_channels_empty">
            <div className="x_edu_channel_h1">{$ui("no_channels")}</div>
          </div>}

              {Boolean(getPentaDesign(penta)['em'].length) && getPentaDesign(penta)['em']
            .map(oneChannel =>
              <Channel
                view='line'
                videos={false}
                penta={penta}
                // className='x_edu_chan--33'
                key={`em_${oneChannel.id}`}
                uniq={oneChannel.id}
                // gates={oneChannel.gates}
                // design={pentaDesign}
                {...oneChannel}
              />
            )}

        </div>
          </div>
        </div>
      }

      {view.includes('same') && <div className='x_edu_content'>
        <div className='x_edu_content_inner'>
          <div className="_fv _fgap06">
            <div className='x_edu_content_h x_edu_content_h--2'><Svg width={28} height={28}>{penta_same}</Svg> {$ui('penta_same_channels')} <div className="x_choose_menu_counter" style={{ fontSize: '0.7em' }}>
              {getPentaDesign(penta)['same'].length}
            </div>

          </div>
            {/* <div className="x_edu_content_desc">
            <Txt>{$ui('penta_same_channels', 'rich')}</Txt>
          </div> */}
        </div>

        <div className="x_edu_channels_inline">

            {!getPentaDesign(penta)['same'].length && <div className="x_edu_channels_empty">
            {$ui("no_channels")}
          </div>}

            {Boolean(getPentaDesign(penta)['same'].length) && getPentaDesign(penta)['same']
            .map(oneChannel =>
              <Channel
                view='line'
                videos={false}
                penta={penta}
                // className='x_edu_chan--33'
                key={`same_${oneChannel.id}`}
                uniq={oneChannel.id}
                // gates={oneChannel.gates}
                // design={pentaDesign}
                {...oneChannel}
              />
            )}
        </div>
        </div>
      </div>}

      {view.includes('dom') && <div className='x_edu_content'><div className='x_edu_content_inner'>
        <div className="_fv _fgap06">
          <div className='x_edu_content_h x_edu_content_h--2'>
            <Svg width={28} height={28}>{penta_dom}</Svg> {$ui('penta_dom_channels')}
            <div className="x_choose_menu_counter" style={{ fontSize: '0.7em' }}>
              {getPentaDesign(penta)['dom'].length}
            </div>

          </div>
          <div className="x_edu_content_desc">
            <Txt>{$ui('penta_dom_channels', 'rich')}</Txt>
          </div>
        </div>
        <div className="x_edu_content_inner" key='1'>

          {!getPentaDesign(penta)['dom'].length
            && <div className="x_edu_channels_empty">
              {$ui("no_channels")}
            </div>}

          {/* <div className="_f100 _fwrap">
              {Boolean(getPentaDesign(penta)['dom'].length) && getPentaDesign(penta)['dom']
                .map(oneChannel =>
                  <Channel
                    videos={false}
                    key={`dom_${oneChannel.id}`}
                    uniq={oneChannel.id}
                    // gates={oneChannel.gates}
                    // design={pentaDesign}
                    penta={penta}
                    {...oneChannel}
                    className='x_edu_chan--33'
                  />
            )}
          </div> */}

          <div className="_f _fspace _f100">
            {penta.members.map((oneMember, ind) => {
              const memberId = oneMember.avatar.token;
              const otherMember = penta.members.filter(one => one.avatar.token !== memberId)[0];
              const design = oneMember.avatar.design;
              return (
                <div
                  // className={`x_edu_activations_column x_edu_activations_column--penta x_edu_activations_column--penta${ind + 1}`}
                  key={`dom_avas_${oneMember.avatar.token}`}
                  className='_fv _fgap1'
                  style={{ width: `${(100 / penta.members.length - 2)}%` }}
                >

                  <div
                    className='x_edu_h2 x_edu_h2--sticky'>
                    {/* <div>{$ui('penta_channels_of')}</div> */}
                    <div className='x_ava_inline0' style={{ width: '100%' }}>
                      {/* <span className='x_ava_inline' style={{ display: 'inline-flex' }}>
                        <Avatar style={{ fontSize: '1em' }} avatar={oneMember.avatar} />
                      </span> */}
                      <span
                        className={`x_ava_inline_name ellipsis x_ava_inline_name--${oneMember.avatar.design.type.toLowerCase()}`}
                      // style={{ marginRight: '-0.1em' }}
                      >
                        <AvatarName avatar={oneMember.avatar} />
                      </span>
                      {` ${$ui('penta_dom_over')} `}
                      {/* <span className='x_ava_inline' style={{ display: 'inline-flex' }}>
                        <Avatar style={{ fontSize: '1em' }} avatar={otherMember.avatar} />
                      </span> */}
                      <span
                        className={`x_ava_inline_name ellipsis x_ava_inline_name--${otherMember.avatar.design.type.toLowerCase()}`}
                      // style={{ marginRight: '-0.4em' }}
                      >
                        <AvatarName avatar={otherMember.avatar} />
                      </span>
                    </div>
                  </div>

                  {Boolean(getDomChannels(penta, oneMember.avatar.token).length) && getDomChannels(penta, oneMember.avatar.token)
                    .map((oneChannel, ind) => <div
                      // className='_fv _fgap1 _f100'
                      key={`dom_avas_${ind}_${oneMember.avatar.token}`}
                    >
                      <Channel
                        videos={false}
                        key={`dom_${oneChannel.id}`}
                        uniq={oneChannel.id}

                        // gates={oneChannel.gates}
                        // design={pentaDesign}
                        penta={penta}
                        {...oneChannel}
                        className='x_edu_chan'
                      />
                    </div>
                  )}

                  {!Boolean(getDomChannels(penta, oneMember.avatar.token).length) && <>
                    <div className="x_edu_channels_empty">
                      {$ui("no_channels_0")}
                    </div>
                  </>}


                </div>
              )
            })}
          </div>
        </div>
      </div>
      </div>}

      {view.includes('individual') &&
        <div className='x_edu_content'>
          <div className='x_edu_content_inner'>
            <div className="_fv _fgap2">
              <div className='x_edu_content_h x_edu_content_h--2'>
                {$ui('penta_individual_channels')}
              </div>

              <div className="x_edu_activations_table x_edu_activations_table--penta">

                {penta.members.map((oneMember, ind) => {
                  const memberId = oneMember.avatar.token;
                  const design = oneMember.avatar.design;
                  return (
                    <div
                      className={`x_edu_activations_column x_edu_activations_column--penta x_edu_activations_column--penta${ind + 1}`}
                      key={`dom_avas_${oneMember.avatar.token}`}
                    >
                      <div className="x_edu_channels">
                        <div
                          className='x_edu_h2 x_edu_h2--sticky'>
                          {/* <div>{$ui('penta_channels_of')}</div> */}
                          <div className='x_ava_inline0' style={{ width: '100%' }}>
                            <span className='x_ava_inline' style={{ display: 'inline-flex' }}>
                              <Avatar style={{ fontSize: '1em' }} avatar={oneMember.avatar} />
                            </span>
                            <span
                              className={`x_ava_inline_name ellipsis x_ava_inline_name--${oneMember.avatar.design.type.toLowerCase()}`}
                            // style={{ width: '100%' }}
                            >
                              <AvatarName avatar={oneMember.avatar} />
                            </span>
                          </div>
                        </div>
                        {pentaDesign.channels
                          .filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId))
                          .map(oneChannel => <Channel
                            videos={false}
                            key={`chof_${oneMember.avatar.token}_${oneChannel.id}`}
                            uniq={oneChannel.id}
                            gates={oneChannel.gates}
                            design={design}
                            {...oneChannel}
                          />)}


                        {!pentaDesign.channels
                          .filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId)).length && <div className="x_edu_channels_empty">
                            {$ui("no_channels_0")}
                          </div>}

                      </div>
                    </div>
                  )
                })}

              </div>

            </div>

          </div>
      </div>}









    </>
  )
})




