import React, { useContext } from 'react';
import {
  penta_comp,
  penta_em,
  penta_same,
  penta_dom,
  getPentaDesign
} from '../utilsPenta';

import { LangContext } from '../../contexts/langContext';
import { Svg } from '../UiKit/Svg';
import { TooltipH } from '../UiKit/TooltipH';


export const PentaStats = ({
  markers,
  handleMarkers = () => { },
  penta = {},
  size = 20
}) => {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  return (<>
    <div className="x_penta_stats">

      <TooltipH title={$ui('penta_composite9')} placement='bottom'>
        <div className="x_penta_stat1">
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_comp}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getPentaDesign(penta)['nine']}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_em_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getPentaDesign(penta)['em'].length) ? '' : 'x_penta_stat1--0'} ${markers === 'em' ? 'active' : ''}`}
          onClick={() => handleMarkers('em')}
        >
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_em}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getPentaDesign(penta)['em'].length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_same_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getPentaDesign(penta)['same'].length) ? '' : 'x_penta_stat1--0'} ${markers === 'same' ? 'active' : ''}`}
          onClick={() => handleMarkers('same')}
        >
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_same}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getPentaDesign(penta)['same'].length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_dom_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getPentaDesign(penta)['dom'].length) ? '' : 'x_penta_stat1--0'} ${markers === 'dom' ? 'active' : ''}`}
          onClick={() => handleMarkers('dom')}
        >
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_dom}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getPentaDesign(penta)['dom'].length}
          </div>
        </div>
      </TooltipH>


    </div>
  </>
  );
};


