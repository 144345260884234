export const bwColor = '#8e6767';

export const theme = {
  designColorSimple: '#8e6767',
  designColor: '#f45656',
  designColor2: '#ffa8a8',
  personalityColor: '#000000',
  highlightColor: '#002bff',
  personalityColor2: '#878787',
  highlightColorInactive: '#fcfcfc',
  highlightDimmed: '#c0c0c0',
  openColor: '#FFF',
  openColor2: '#d7d7d743',
  authorityColor: 'black',
  definedColor: bwColor,

  personalityColorC0: '#4a4a4a',
  designColorC0: '#747474',
  lineOpenC0: '#e9e9e9',


  personalityColorC1: '#00a000',
  designColorC1: '#24c424',
  lineOpenC1: '#ffffff56',

  personalityColorC2: '#005ec3',
  designColorC2: '#499df8',
  lineOpenC2: '#ffffff56',

  lineOpen: '#FFF',
  personalityColoroth: 'blue',
  line0: '#FFF',
  line0comp: 'transparent',
  highlightedGateInnerColor: '#002bff',
  highlightedGateColor: '#002bff',
  // ...colorThemeInner
}

const colorsClassic = {
  HD: '#fff9a2',
  AA: '#397944',
  TT: '#6C4B2C',
  GC: '#FEF471',
  HT: '#A7302C',
  SN: '#6C4B2C',
  SP: '#6C4B2C',
  SL: '#A7302C',
  RT: '#6C4B2C',
}



export const linesBw = {
  base: {
    prs: theme.personalityColor,
    des: theme.designColorSimple,
    open: theme.lineOpen,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite0: {
    prs: theme.personalityColorC0,
    des: theme.designColorC0,
    open: theme.lineOpenC0,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite1: {
    prs: theme.personalityColorC1,
    des: theme.designColorC1,
    open: theme.lineOpenC1,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite2: {
    prs: theme.personalityColorC2,
    des: theme.designColorC2,
    open: theme.lineOpenC2,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
}


export const linesClassic = {
  base: {
    prs: theme.personalityColor,
    des: theme.designColor,
    open: theme.lineOpen,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite0: {
    prs: theme.personalityColorC0,
    des: theme.designColorC0,
    open: theme.lineOpenC0,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite1: {
    prs: theme.personalityColorC1,
    des: theme.designColorC1,
    open: theme.lineOpenC1,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
  composite2: {
    prs: theme.personalityColorC2,
    des: theme.designColorC2,
    open: theme.lineOpenC2,
    chakraOpen: theme.openColor,
    chakraDefined: theme.definedColor,
  },
}

export const colors = {
  bw: {
    chakraHDopen: theme.openColor,
    chakraHDdefined: theme.definedColor,
    chakraAAopen: theme.openColor,
    chakraAAdefined: theme.definedColor,
    chakraTTopen: theme.openColor,
    chakraTTdefined: theme.definedColor,
    chakraGCopen: theme.openColor,
    chakraGCdefined: theme.definedColor,
    chakraHTopen: theme.openColor,
    chakraHTdefined: theme.definedColor,
    chakraSLopen: theme.openColor,
    chakraSLdefined: theme.definedColor,
    chakraSPopen: theme.openColor,
    chakraSPdefined: theme.definedColor,
    chakraSNopen: theme.openColor,
    chakraSNdefined: theme.definedColor,
    chakraRTopen: theme.openColor,
    chakraRTdefined: theme.definedColor,

    ...linesBw

  },
  colorsClassic: {
    chakraHDopen: theme.openColor,
    chakraHDdefined: colorsClassic.HD,
    chakraAAopen: theme.openColor,
    chakraAAdefined: colorsClassic.AA,
    chakraTTopen: theme.openColor,
    chakraTTdefined: colorsClassic.TT,
    chakraGCopen: theme.openColor,
    chakraGCdefined: colorsClassic.GC,
    chakraHTopen: theme.openColor,
    chakraHTdefined: colorsClassic.HT,
    chakraSLopen: theme.openColor,
    chakraSLdefined: colorsClassic.SL,
    chakraSPopen: theme.openColor,
    chakraSPdefined: colorsClassic.SP,
    chakraSNopen: theme.openColor,
    chakraSNdefined: colorsClassic.SN,
    chakraRTopen: theme.openColor,
    chakraRTdefined: colorsClassic.RT,

    ...linesClassic

  },
  colorsModern: {
    chakraHDopen: theme.openColor,
    chakraHDdefined: theme.definedColor,
    chakraAAopen: theme.openColor,
    chakraAAdefined: theme.definedColor,
    chakraTTopen: theme.openColor,
    chakraTTdefined: theme.definedColor,
    chakraGCopen: theme.openColor,
    chakraGCdefined: theme.definedColor,
    chakraHTopen: theme.openColor,
    chakraHTdefined: theme.definedColor,
    chakraSLopen: theme.openColor,
    chakraSLdefined: theme.definedColor,
    chakraSPopen: theme.openColor,
    chakraSPdefined: theme.definedColor,
    chakraSNopen: theme.openColor,
    chakraSNdefined: theme.definedColor,
    chakraRTopen: theme.openColor,
    chakraRTdefined: theme.definedColor,

    ...linesBw

  },


}





