import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Avatar } from './UiKit/Avatar';
import { AvatarName } from './UiKit/AvatarName';
import { ChatsNotifications } from './UiKit/ChatsNotifications';
import { DropDown } from './UiKit/DropDown';
import { Notifications } from './UiKit/Notifications';


export function UserPanel({ userId }) {

  const navigate = useNavigate();

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  return (
    <>
      <div className="x_0_right">

        {context.me && <ChatsNotifications />}

        {context.me && <Notifications />}

          <DropDown
          id='userdrop'
          customDrop={(handleClose = () => void (0)) => <div className="x_settings_pop">
            <div className="x_settings_section">
              <div className="x_settings_section_title">
                {$ui('personal_settings')}
              </div>


              {Boolean(context.me) && <div className="x_settings_li">
                <div className="x_settings_action"
                  style={{
                    columnGap: '.4em',
                    marginLeft: '-0.4em',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/u/${context.me.user.token}/info`);
                    handleClose()
                  }}
                >
                  <Avatar
                    avatar={context.me.avatar}
                    style={{
                      fontSize: '0.8em',
                      marginTop: '.04em',
                      marginLeft: '.04em',
                      // marginRight: '-0.3em',
                      // marginTop: '-0.3em',
                      // width: '3em',
                      // height: '3em'
                    }}
                    size='sm'
                  />
                  <div className="x_choose_filter_title"
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <AvatarName avatar={context.me.avatar} />
                  </div>

                </div>


                <a className="x_button x_button--ghost" href='/auth0/logout'>

                  <div className="t_icon t_icon--20">
                    <div>logout</div>
                  </div>
                </a>

              </div>}






              {!Boolean(context.me) && <div className="x_settings_li">

                <div className="x_settings_action"
                  onClick={() => navigate(`/auth0/login`)}
                >
                  <div className="t_icon t_icon--20">
                    <div>login</div>
                  </div>

                  {$ui('login')}
                </div>

              </div>}


              <div className="x_settings_li">
                <div className={`x_settings_action`}
                  onClick={() => {
                    navigate(`/u/${context.me.user.token}/settings`)
                  }}
                >
                  <div className="t_icon">
                    settings
                  </div>
                  <div>
                    <div className="x_choose_filter_title">{$ui('settings')}</div>
                  </div>
                </div>
              </div>


            </div>



            <div className="x_settings_section">
              <div className="x_settings_section_title">
                {$ui('language')}
              </div>

                <div className="x_settings_ul expanded">


                    <div className="x_settings_li">
                      <div className={`x_settings_action ${context.language === 'en' ? 'active' : ''}`}
                        onClick={() => {
                          context.setLanguage('en')
                          handleClose()
                        }}
                      >
                        <div className="t_icon">
                          <div>language</div>
                        </div>
                        <div>
                          <div className="x_choose_filter_title">English</div>
                        </div>
                        <div className="x_settings_right">
                          <div className={`x_settings_check ${context.language === 'en' ? 'active' : ''}`}>
                            <div className="t_icon t_icon--20">
                              {context.language === 'en' ? 'radio_button_checked' : 'radio_button_unchecked'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="x_settings_li">
                      <div className={`x_settings_action ${context.language === 'ru' ? 'active' : ''}`}
                        onClick={() => {
                          context.setLanguage('ru')
                          handleClose()
                        }}
                      >
                        <div className="t_icon">
                          language
                        </div>
                        <div>
                          <div className="x_choose_filter_title">Русcкий</div>
                        </div>
                        <div className="x_settings_right">

                          <div className={`x_settings_check ${context.language === 'ru' ? 'active' : ''}`}>
                            <div className="t_icon t_icon--20">
                              {context.language === 'ru' ? 'radio_button_checked' : 'radio_button_unchecked'}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
              </div>
            </div>

            <div className="x_settings_section">
              <div className="x_settings_section_title">
                {$ui('settings_color_mode')}
              </div>

              <div className="x_settings_ul expanded">


                <div className="x_settings_li">
                  <div className={`x_settings_action ${context.state.colorMode === 'bw' ? 'active' : ''}`}
                    onClick={() => {
                      context.setState.setColorMode('bw')
                      handleClose()
                    }}
                  >
                    <div className="t_icon">
                      <div>palette</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">{$ui('settings_color_mode_bw')}</div>
                    </div>
                    <div className="x_settings_right">
                      <div className={`x_settings_check ${context.state.colorMode === 'bw' ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {context.state.colorMode === 'bw' ? 'radio_button_checked' : 'radio_button_unchecked'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="x_settings_li">
                  <div className={`x_settings_action ${context.state.colorMode === 'colorsClassic' ? 'active' : ''}`}
                    onClick={() => {
                      context.setState.setColorMode('colorsClassic')
                      handleClose()
                    }}
                  >
                    <div className="t_icon">
                      <div>palette</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">{$ui('settings_color_mode_colors')}</div>
                    </div>
                    <div className="x_settings_right">

                      <div className={`x_settings_check ${context.state.colorMode === 'colorsClassic' ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {context.state.colorMode === 'colorsClassic' ? 'radio_button_checked' : 'radio_button_unchecked'}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="x_settings_section">
              <div className="x_settings_section_title">
                {$ui('settings')}
              </div>
              <div className="x_settings_ul expanded">

                <div className="x_settings_li">
                  <div
                    className={`x_settings_action ${context.state.hideNames ? 'active' : ''}`}
                    onClick={() => {
                      context.setState.setHideNames(prev => !prev)
                      handleClose()
                    }}
                  >
                    <div className="t_icon">
                      <div>visibility_off</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">{$ui('view_options_privacy_names')}</div>
                    </div>
                    <div className="x_settings_right">

                      <div className={`x_settings_check ${context.state.hideNames ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {context.state.hideNames ? 'toggle_on' : 'toggle_off'}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <div className="x_settings_ul expanded">
                <div className="x_settings_li">
                  <div
                    className={`x_settings_action ${context.state.version === 'β' ? 'active' : ''}`}
                    onClick={() => {
                      context.setState.setVersion(prev => prev === '' ? 'β' : '')
                      handleClose()
                    }}
                  >
                    <div className="t_icon">
                      <div>science</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">
                        {$ui('beta_toggler')}
                      </div>
                    </div>
                    <div className="x_settings_right">

                      <div className={`x_settings_check ${context.state.version === 'β' ? 'active' : ''}`}>
                      <div className="t_icon t_icon--20">
                        {context.state.version === 'β' ? 'toggle_on' : 'toggle_off'}
                      </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          >


            <div className="x_user0">

            {context.state.hideNames && <div className="x_noti0 x_noti0--number">
              <div className="x_noti0_number">
                <div className="t_icon">visibility_off</div>
              </div>
            </div>}

              <div className="t_icon t_icon--60">
                menu
              </div>
            </div>

          </DropDown>



        </div>

    </>
  )
}