import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { QR } from '../UiKit/QR';
import { Button } from './Button';
import { PentaName, PentaNameText } from '../Penta/PentaName';

export const PentaShare = React.memo(({ penta = {}, handleClose }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;


  const [copydone, setCopydone] = useState(false);


  const inputRef = useRef(null);


  const [name, setName] = useState(penta.name);



  const getPentaUrl = () => `https://hd.cafe/p/${penta.token}`

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getPentaUrl())
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };

  const [pentaInner, setPentaInner] = useState(penta)
  useEffect(() => {
    if (
      penta.token !== pentaInner.token
      || penta.members.length !== pentaInner.members.length
      || penta.members.name !== pentaInner.members.name
    ) {
      setPentaInner(penta)
    }
  }, [penta])

  const inputName = useRef(null);

  return (
    <>
      <div className="m_b_c_scroll">

        <div className="m_b_cols">
          <div className="m_b_form0">
            <div className="m_b_form">




              {Boolean(penta.name) && <div className="x_calc_form_line">


                {!Boolean(penta.name) && <div className="x_calc_form0">
                  <input
                    disabled={false}
                    ref={inputName}
                    type="text"
                    className="x_input x_input--url"
                    onFocus={(e) => e.target.select()}
                    placeholder={$ui('penta_name_placeholder')}
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={pentaInner.name}
                  />
                  <div className="x_input_right">
                    <Button
                      onClick={() => {
                        context.x.updatePenta({
                          'token': penta.token,
                          'name': name
                        }, (newPenta) => setPentaInner(newPenta))
                      }}
                    >Save</Button>
                  </div>

                </div>}




                <div className="x_calc_form0">
                  <input
                    ref={inputRef}
                    disabled={true}
                    type="text"
                    className="x_input x_input--url x_input--disabled"
                    onFocus={(e) => e.target.select()}
                    defaultValue={penta.name}
                  />
                </div>


                {$ui('penta_name_hidden')}
              </div>}




              {/* <hr style={{ color: '#fff' }} /> */}

              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_share')}</div>
              </div>



              <div className="x_info_form_preview_sections">





                <div className="m_b_form">

                  <div className="x_calc_form_line">
                    <div className="x_calc_form0">
                      <input
                        ref={inputRef}
                        disabled={true}
                        type="text"
                        className="x_input x_input--url x_input--disabled"
                        onFocus={(e) => e.target.select()}
                        defaultValue={getPentaUrl()}
                      />
                      <div className="x_input_right">
                        <div className="x_button x_button--sm" onClick={() => {
                          handleCopy()
                          setCopydone(true)
                          setTimeout(() => {
                            setCopydone(false)
                          }, 1000);
                        }}>


                          <CopyDone copyDone={copydone} />

                          <div className="t_icon">
                            <div>content_copy</div>
                          </div>

                          {$ui('pop_button_copy')}
                        </div>
                      </div>

                    </div>
                  </div>


                  <div className="x_calc_form_line">
                    {/* <div className="x_calc_label">
                <div>Scan QR code</div>
              </div> */}
                    <div className="x_calc_qr">
                      <QR
                        url={getPentaUrl()}
                      />

                    </div>
                  </div>


                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
})



export const CopyDone = ({ copyDone }) => {
  const [copydone, setCopydone] = useState(false);

  useEffect(() => {
    if (copyDone !== copydone) {
      setCopydone(copyDone)
      setTimeout(() => {
        setCopydone(false)
      }, 1000);
    }
  }, [copyDone])


  return (
    <div
      className={copydone ? "x_button_ok inactive active" : "x_button_ok inactive"}
    // style={{ opacity: copydone ? 1 : 0 }}
    >
      <div className="t_icon t_icon--60">check</div>
    </div>
  )
}


